<template>
    <div class="d-flex flex-column fill-height">
        <v-col class="pa-0 ma-0 mb-auto">
            <v-row no-gutters>
                <v-col cols="12" class="font-weight-bold document-caption d-flex flex-row justify-start">{{ Office__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ $t('message.grossMarginCalculation') }}</v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.eta') }}:</span>
                    <span class="ml-3">{{ formatDate(document.GrossMargin.etadate) }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.species') }}:</span>
                    <span class="ml-3" v-for="(species, index) in Species__names" :key="index">{{ species }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.contractInvoice') }}:</span>
                    <span class="ml-3">{{ document.GrossMargin.related_document }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.volume') }}:</span>
                    <span class="ml-3">{{ document.GrossMargin.volume }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.salesType') }}:</span>
                    <span class="ml-3" v-if="GrossMargin__contract_id != null && Contract__salestype_id == 1">{{ $t('message.direct') }}</span>
                    <span class="ml-3" v-if="GrossMargin__contract_id != null && Contract__salestype_id == 5">{{ $t('message.service') }}</span>
                    <span class="ml-3" v-if="GrossMargin__localsale_id != null">{{ $t('message.warehouse') }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.paymentTerms') }}:</span>
                    <span class="ml-3">{{ document.GrossMargin.payment_term }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}:</span>
                    <span class="ml-3">{{ document.GrossMargin.customer }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.dueDate') }}:</span>
                    <span class="ml-3">{{ formatDate(document.GrossMargin.due) }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.supplier') }}:</span>
                    <span class="ml-3">{{ document.GrossMargin.supplier }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.received') }}:</span>
                    <span class="ml-3">{{ formatDate(document.GrossMargin.received) }}</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.agent') }}:</span>
                    <span class="ml-3" v-if="document.GrossMargin.main_supplier != null">{{ document.GrossMargin.main_supplier }}</span>
                    <span class="ml-3" v-else>{{ $t('message.na') }}</span>
                </v-col>
                <v-col cols="5">
                    <span class="font-weight-bold">{{ $t('message.overdueDays') }}:</span>
                    <span class="ml-3" v-if="document.GrossMargin.overdue != null">{{ document.GrossMargin.overdue }}</span>
                    <span class="ml-3" v-else>{{ $t('message.na') }}</span>
                </v-col>
            </v-row>
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.currency') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">%</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-20-pct">{{ accountCurrency }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-20-pct">USD</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.sellingPrice') }}</td>
                        <td class="text-right">100.00</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__selling,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.selling,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.purchasePrice') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.purchase,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__purchase,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.purchase,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.bankCharges') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.shortpayment,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__shortpayment,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.shortpayment,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.freightInsuranceCost') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.freightstorage,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__freightstorage,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.freightstorage,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.carriageOutwards') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.carriage,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__carriage,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.carriage,'0,0.00') }}</td>
                    </tr>
                    <tr v-if="source == 'whsales_invoice'">
                        <td>{{ $t('message.grossMarginsWarehouseCost') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.warehousecost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__warehousecost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.warehousecost,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.commission') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.commission,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__commission,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.commission,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.paymentTermsSellingPrice') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.paymenttermcost,'0,0.00') }}</td>
                        <td class="text-right">{{  formatThisNumber(GrossMargin__paymenttermcost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.paymenttermcost,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.latePayment') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.latepaymentcost,'0,0.00') }}</td>
                        <td class="text-right">{{  formatThisNumber(GrossMargin__latepaymentcost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.latepaymentcost,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('message.otherCosts') }}</td>
                        <td class="text-right">{{ formatThisNumber(percentages.othercost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(GrossMargin__othercost,'0,0.00') }}</td>
                        <td class="text-right">{{ formatThisNumber(convertedAmounts.othercost,'0,0.00') }}</td>
                    </tr>
                    <tr v-if="Agent__has_commission == 0">
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.margin') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(percentages.grossmargin,'0,0.00') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(GrossMargin__grossmargin,'0,0.00') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(convertedAmounts.grossmargin,'0,0.00') }}</td>
                    </tr>
                    <template v-if="Agent__has_commission == 1">
                        <tr>
                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.margin') }}</td>
                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(percentages.basicgrossmargin,'0,0.00') }}</td>
                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(GrossMargin__basicgrossmargin,'0,0.00') }}</td>
                            <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right">{{ formatThisNumber(convertedAmounts.basicgrossmargin,'0,0.00') }}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="font-weight-bold">
                                <span v-if="Agent__CommissionValue__minbalance > 0">{{ $t('message.minimumCommission') }}</span>
                                <span v-else>{{ $t('message.commission') }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{{ appConstants.company }}</td>
                            <td class="text-right">{{ formatThisNumber(GrossMargin__mincomhq,'0,0.00') }}</td>
                            <td class="text-right">{{ formatThisNumber(Agent__CommissionValue__mincomhqamount,'0,0.00') }}</td>
                            <td class="text-right">{{ formatThisNumber(convertedAmounts.mincomhqamount,'0,0.00') }}</td>
                        </tr>
                        <tr>
                            <td>{{ Agent__title }}</td>
                            <td class="text-right">{{ formatThisNumber(GrossMargin__mincomsub,'0,0.00') }}</td>
                            <td class="text-right">{{ formatThisNumber(Agent__CommissionValue__mincomsubamount,'0,0.00') }}</td>
                            <td class="text-right">{{ formatThisNumber(convertedAmounts.mincomsubamount,'0,0.00') }}</td>
                        </tr>
                        <template v-if="Agent__CommissionValue__minbalance > 0">
                            <tr>
                                <td class="border-top-1">{{ $t('message.balance') }}</td>
                                <td class="border-top-1 text-right"></td>
                                <td class="border-top-1 text-right">{{ formatThisNumber(Agent__CommissionValue__minbalance,'0,0.00') }}</td>
                                <td class="border-top-1 text-right">{{ formatThisNumber(convertedAmounts.minbalance,'0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="pt-3 font-weight-bold">{{ $t('message.additionalCommission') }}</td>
                            </tr>
                            <tr>
                                <td>{{ appConstants.company }}</td>
                                <td class="text-right">{{ formatThisNumber(additionalCommissionHqPercentage,'0,0.00') }}</td>
                                <td class="text-right">{{ formatThisNumber(Agent__CommissionValue__addcomhqamount,'0,0.00') }}</td>
                                <td class="text-right">{{ formatThisNumber(convertedAmounts.addcomhqamount,'0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td class="pb-3">{{ Agent__title }}</td>
                                <td class="pb-3 text-right">{{ formatThisNumber(additionalCommissionAgentPercentage,'0,0.00') }}</td>
                                <td class="pb-3 text-right">{{ formatThisNumber(Agent__CommissionValue__addcomsubamount,'0,0.00') }}</td>
                                <td class="pb-3 text-right">{{ formatThisNumber(convertedAmounts.addcomsubamount,'0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="font-weight-bold border-top-1">{{ $t('message.totalCommissionFull') }}</td>
                            </tr>
                            <tr>
                                <td>{{ appConstants.company }}</td>
                                <td class="text-right">{{ formatThisNumber(percentages.grossmarginhq,'0,0.00') }}</td>
                                <td class="text-right">{{ formatThisNumber(Agent__CommissionValue__grossmarginhq,'0,0.00') }}</td>
                                <td class="text-right">{{ formatThisNumber(convertedAmounts.grossmarginhq,'0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td class="border-bottom-1">{{ Agent__title }}</td>
                                <td class="border-bottom-1 text-right">{{ formatThisNumber(percentages.grossmarginsub,'0,0.00') }}</td>
                                <td class="border-bottom-1 text-right">{{ formatThisNumber(Agent__CommissionValue__grossmarginsub,'0,0.00') }}</td>
                                <td class="border-bottom-1 text-right">{{ formatThisNumber(convertedAmounts.grossmarginsub,'0,0.00') }}</td>
                            </tr>
                        </template>
                    </template>
                </template>
            </v-simple-table>
        </v-col>
        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
            <v-simple-table
                class="specification-table"
                dense
            >
                <template v-slot:default>
                    <tr class="table-header">
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1">{{ $t('message.grossMargin') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-10-pct">{{ formatThisNumber(percentages.grossmargin,'0,0.00') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-20-pct">{{ formatThisNumber(GrossMargin__grossmargin,'0,0.00') }}</td>
                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 text-right width-20-pct">{{ formatThisNumber(convertedAmounts.grossmargin,'0,0.00') }}</td>
                    </tr>
                    <tr>
                        <td colspan="4" class="d-flex flex-row">
                            <div style="width: 100px">{{ $t('message.destination') }}</div>
                            <span>{{ document.GrossMargin.destination }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="d-flex flex-row">
                            <div style="width: 100px">{{ $t('message.productGroup') }}</div>
                            <span>{{ document.GrossMargin.productgroup }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="d-flex flex-row">
                            <div style="width: 100px">{{ $t('message.marketSegment') }}</div>
                            <span>{{ document.GrossMargin.marketsegment}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="d-flex flex-row">
                            <div style="width: 100px">{{ $t('message.productOrigin') }}</div>
                            <span>{{ document.GrossMargin.origin }}</span>
                        </td>
                    </tr>
                </template>
            </v-simple-table>
        </div>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
import { api } from "../../../api";
import {formatDate, numberFormat} from "Helpers/helpers";
import {
    appConstants,
    // currencies,
    offices,
    // uofm
} from "../../../store/modules/appic/constants"

export default {
    name: "PrintGrossMargin",
    props: ['grossMarginId','source'],
    data() {
        return {
            appConstants: appConstants,
            convertedAmounts: {
                addcomhqamount: 0,
                addcomsubamount: 0,
                basicgrossmargin: 0,
                carriage: 0,
                commission: 0,
                freightstorage: 0,
                grossmargin: 0,
                latepaymentcost: 0,
                minbalance: 0,
                mincomhqamount: 0,
                mincomsubamount: 0,
                othercost: 0,
                paymenttermcost: 0,
                purchase: 0,
                selling: 100,
                shortpayment: 0,
                warehousecost: 0,
            },
            // currencies: currencies,
            currencies: [],
            document: {
                GrossMargin: {
                    agent: null,
                    customer: null,
                    document_title: null,
                    destination: '',
                    due: null,
                    etadate: null,
                    marketsegment: '',
                    office: null,
                    origin: '',
                    overdue: 0,
                    payment_term: null,
                    productgroup: '',
                    received: null,
                    related_document: null,
                    species: [],
                    supplier: null,
                    volume: 0,
                }
            },
            // documentType: 'contract',
            loaded: false,
            offices: offices,
            percentages: {
                addcomhqamount: 0,
                addcomsubamount: 0,
                basicgrossmargin: 0,
                carriage: 0,
                commission: 0,
                freightstorage: 0,
                grossmargin: 0,
                latepaymentcost: 0,
                minbalance: 0,
                mincomhqamount: 0,
                mincomsubamount: 0,
                othercost: 0,
                paymenttermcost: 0,
                purchase: 0,
                selling: 100,
                shortpayment: 0,
                warehousecost: 0,
            },
            //calculated agent commission values 2021-09-26
            Agent__addcomhq: 0,
            Agent__has_commission: 0,
            Agent__mincomhq: 0,
            Agent__mincomsub: 0,
            Agent__title: '',
            Agent__CommissionValue__mincomhq: 0,
            Agent__CommissionValue__mincomhqamount: 0,
            Agent__CommissionValue__mincomsubamount: 0,
            Agent__CommissionValue__mincomsub: 0,
            Agent__CommissionValue__minbalance: 0,
            Agent__CommissionValue__gmpct: 0,
            Agent__CommissionValue__grossmarginhq: 0,
            Agent__CommissionValue__grossmarginsub: 0,
            Agent__CommissionValue__addcomhqamount: 0,
            Agent__CommissionValue__addcomsubamount: 0,
            Contract__salestype_id: 1,
            GrossMargin__carriage: 0,
            GrossMargin__commission: 0,
            GrossMargin__contract_id: null,
            GrossMargin__currency_id: 2,
            GrossMargin__currencyrate: 1,
            GrossMargin__freightstorage: 0,
            GrossMargin__grossmargin: 0,
            GrossMargin__latepaymentcost: 0,
            GrossMargin__localsale_id: null,
            GrossMargin__office_id: 1,
            GrossMargin__othercost: 0,
            GrossMargin__paymenttermcost: 0,
            GrossMargin__purchase: 0,
            GrossMargin__selling: 0,
            GrossMargin__shortpayment: 0,
            GrossMargin__warehousecost: 0,
            Species__names: [],
            Office__title: null,
        }
    },
    computed: {
        accountCurrency () {
            let currencyObj = this.currencies.find(c => c.Currency.id == this.GrossMargin__currency_id)
            if(currencyObj) return currencyObj.Currency.code
            return ''
        },
        additionalCommissionAgentPercentage() {
            return (1 - this.GrossMargin__addcomhq) * 100
        },
        additionalCommissionHqPercentage() {
            return this.GrossMargin__addcomhq * 100
        },
    },
    methods: {
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/currencies",{
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        loadDocumentDetailById (val){
            return new Promise((resolve, reject) => {
                api
                    .get('/print/grossmargins/' + val + '/document-details')
                    .then(response => {
                        resolve(response.data.data[0])
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        loadGrossMarginById (grossMarginId) {
            return new Promise((resolve, reject) => {
                api
                    .get("/print/grossmargins/" + grossMarginId,{
                    })
                    .then(response => {
                        for(let key in response.data.data[0]){
                            this[key] = response.data.data[0][key]
                        }
                        let office = this.offices.find(o => o.Office.id == this.GrossMargin__office_id)
                        if(office){
                            this.Office__title = office.Office.title
                        }
                        if(this.Agent__has_commission == 1){
                            api
                                .get('/print/grossmargins/' + grossMarginId + '/agent-commission')
                                .then( response => {
                                    for(let key in response.data.data){
                                        this['Agent__CommissionValue__' + key] = response.data.data[key]
                                    }
                                    resolve(grossMarginId)
                                })
                        } else {
                            resolve(grossMarginId)
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(()=>{})
            })
        },
        updateConvertedAmounts() {
            return new Promise((resolve, reject) => {
                this.convertedAmounts.carriage = this.GrossMargin__carriage / this.GrossMargin__currencyrate
                this.convertedAmounts.commission = this.GrossMargin__commission / this.GrossMargin__currencyrate
                this.convertedAmounts.freightstorage = this.GrossMargin__freightstorage / this.GrossMargin__currencyrate
                this.convertedAmounts.grossmargin = this.GrossMargin__grossmargin / this.GrossMargin__currencyrate
                this.convertedAmounts.latepaymentcost = this.GrossMargin__latepaymentcost / this.GrossMargin__currencyrate
                this.convertedAmounts.othercost = this.GrossMargin__othercost / this.GrossMargin__currencyrate
                this.convertedAmounts.paymenttermcost = this.GrossMargin__paymenttermcost / this.GrossMargin__currencyrate
                this.convertedAmounts.purchase = this.GrossMargin__purchase / this.GrossMargin__currencyrate
                this.convertedAmounts.selling = this.GrossMargin__selling / this.GrossMargin__currencyrate
                this.convertedAmounts.shortpayment = this.GrossMargin__shortpayment / this.GrossMargin__currencyrate
                this.convertedAmounts.warehousecost = this.GrossMargin__warehousecost / this.GrossMargin__currencyrate
                if(this.Agent__has_commission == 1){
                    this.convertedAmounts.basicgrossmargin = this.GrossMargin__basicgrossmargin / this.GrossMargin__currencyrate
                    this.convertedAmounts.mincomhqamount = this.Agent__CommissionValue__mincomhqamount / this.GrossMargin__currencyrate
                    this.convertedAmounts.mincomsubamount = this.Agent__CommissionValue__mincomsubamount / this.GrossMargin__currencyrate
                    this.convertedAmounts.minbalance = this.Agent__CommissionValue__minbalance / this.GrossMargin__currencyrate
                    this.convertedAmounts.addcomhqamount = this.Agent__CommissionValue__addcomhqamount / this.GrossMargin__currencyrate
                    this.convertedAmounts.addcomsubamount = this.Agent__CommissionValue__addcomsubamount / this.GrossMargin__currencyrate
                    this.convertedAmounts.grossmarginhq = this.Agent__CommissionValue__grossmarginhq / this.GrossMargin__currencyrate
                    this.convertedAmounts.grossmarginsub = this.Agent__CommissionValue__grossmarginsub / this.GrossMargin__currencyrate
                }
                resolve('done')
            })
        },
        updatePercentages() {
            return new Promise((resolve, reject) => {
                this.percentages.carriage = (this.GrossMargin__carriage / this.GrossMargin__selling) * 100
                this.percentages.commission = (this.GrossMargin__commission / this.GrossMargin__commission) * 100
                this.percentages.freightstorage = (this.GrossMargin__freightstorage / this.GrossMargin__selling) * 100
                this.percentages.latepaymentcost = (this.GrossMargin__latepaymentcost / this.GrossMargin__selling) * 100
                this.percentages.othercost = (this.GrossMargin__othercost / this.GrossMargin__selling) * 100
                this.percentages.paymenttermcost = (this.GrossMargin__paymenttermcost / this.GrossMargin__selling) * 100
                this.percentages.purchase = (this.GrossMargin__purchase / this.GrossMargin__selling) * 100
                this.percentages.selling = (this.GrossMargin__selling / this.GrossMargin__selling) * 100
                this.percentages.shortpayment = (this.GrossMargin__shortpayment / this.GrossMargin__selling) * 100
                this.percentages.warehousecost = (this.GrossMargin__warehousecost / this.GrossMargin__selling) * 100
                if(this.Agent__has_commission == 1){
                    this.percentages.basicgrossmargin = (this.GrossMargin__basicgrossmargin / this.GrossMargin__selling) * 100
                    this.percentages.mincomhqamount = (this.Agent__CommissionValue__mincomhqamount / this.GrossMargin__selling) * 100
                    this.percentages.mincomsubamount = (this.Agent__CommissionValue__mincomsubamount / this.GrossMargin__selling) * 100
                    this.percentages.minbalance = (this.Agent__CommissionValue__minbalance / this.GrossMargin__selling) * 100
                    this.percentages.addcomhqamount = (this.Agent__CommissionValue__addcomhqamount / this.GrossMargin__selling) * 100
                    this.percentages.addcomsubamount = (this.Agent__CommissionValue__addcomsubamount / this.GrossMargin__selling) * 100
                    this.percentages.grossmarginhq = (this.Agent__CommissionValue__grossmarginhq / this.GrossMargin__selling) * 100
                    this.percentages.grossmarginsub = (this.Agent__CommissionValue__grossmarginsub / this.GrossMargin__selling) * 100
                }
                this.percentages.grossmargin = (this.GrossMargin__grossmargin/this.GrossMargin__selling) * 100
                resolve('done')
            })
        }
    },
    created () {
        this.getCurrencies()
            .then((currencies) => {
                this.currencies = currencies
            })
        if(this.$route.matched.some(({name}) => name === 'print_gross_margin')){
            this.loadGrossMarginById(this.grossMarginId)
                .then(() => {
                    this.updateConvertedAmounts()
                        .then(()=>{
                            this.updatePercentages().then(()=>{
                                this.loadDocumentDetailById (this.grossMarginId).then((response)=>{
                                    this.document = response
                                    this.loaded = true
                                })
                            })
                            .catch(() => {
                                this.loaded = true
                            })
                    })
                    .catch(() => {
                        this.loaded = true
                    })
                })
                .catch(() => {
                    this.loaded = true
                })
        }
    }
}
</script>

<style>
@font-face {
    font-family: 'AppicArial';
    src: url('/static/fonts/Arial.woff2') format('woff2'),
    url('/static/fonts/Arial.woff2') format('woff'),
    url('/static/fonts/Arial.woff2') format('truetype');
}
.col-2-5 {
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.col-4-5 {
    flex: 0 0 37.5%;
    max-width: 37.5%;
}
.col-9-5 {
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
}
.border-top-1 {
    border-top: 1px solid black !important;
}
.border-bottom-1 {
    border-bottom: 1px solid black !important;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.document-caption {
    font-size: 14px !important;
    line-height: 16px;
}
.footer-note {
    font-size: 8pt !important;
}
.header-title {
    font-size: 18pt !important;
    line-height: 22px;
    font-weight: bold !important;
}
.header-subtitle {
    font-size: 10pt !important;
}
.v-application{
    font-family: 'AppicArial' !important;
    line-height:15px !important;
    font-size:9pt !important;
    padding:2px !important;
}
body {
    width: 18.5cm !important;
    margin: 0 !important;
}
</style>